import Layout from "../layouts";
import React from "react";

const BlogPage = () => (
  <Layout>
    <p className="leads-the-things emboldernised">
      Want to read about our travel or tech ramblings?
    </p>

    <p className="leads-the-things">
      We maintain two blogs. If you check them out don't forget to recommend!
    </p>

    <div className="boxes">
      <div className="box blog">
        <a href="https://blog.squarecat.io">
          <div className="box__details">
            <h3>
              <span role="img" aria-label="laptop emoji">
                👩‍💻
              </span>{" "}
              Tech Blog{" "}
              <span role="img" aria-label="laptop emoji">
                👨‍💻
              </span>
            </h3>
          </div>
        </a>
      </div>
      <div className="box blog">
        <a href="https://travel.squarecat.io">
          <div className="box__details">
            <h3>
              <span role="img" aria-label="earth emoji">
                🌎
              </span>{" "}
              Travel Stories{" "}
              <span role="img" aria-label="palm tree emoji">
                🌴
              </span>
            </h3>
          </div>
        </a>
      </div>
    </div>
  </Layout>
);

export default BlogPage;
